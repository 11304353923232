/*
Extra small devices (portrait phones, less than 576px)
No media query since this is the default in Bootstrap because it is "mobile first"
*/
.no-js section.draggable:hover {
  top: 10% !important;
}

/* Small devices (landscape phones, 576px and up) */
@media (max-width: 374px) {
  section.draggable div.container > h2 {
    font-size: 0.8rem;
  }
  .container {
    padding: 0.3rem 1rem 0rem 1rem;
  }
}

/* Medium devices (tablets, 768px and up) The navbar toggle appears at this breakpoint */
@media (min-width: 768px) {
  .container {
    padding: 1.2rem 3rem;
  }

  section.draggable > div.container {
    padding: 0.7rem 3rem;
  }
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) {
  .container {
    padding: 2rem 3rem;
  }
  section.draggable div.container {
    height: 100% !important;
  }
  section.background div.container > div.animated-load {
    flex-direction: column;
  }

  section.background blockquote {
    max-width: 75%;
    margin-bottom: 1.5rem;
    line-height: unset;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  i {
    width: 2rem !important;
  }
  h2 {
    font-size: 1.2rem;
  }
  section.background h1 {
    font-size: 2rem;
  }
  section.background h2 {
    font-size: 1.3rem;
  }
  section.background p,
  section.background blockquote {
    font-size: 1rem;
  }
  figure.avatar.avatar-xl {
    height: 6rem;
    width: 6rem;
  }
  section.background div.container {
    padding: 2rem 3rem;
    max-height: 95% !important;
  }
  span.stacks {
    position: absolute;
    top: 95% !important;
    display: flex;
    flex-direction: row-reverse;
  }
  section.draggable {
    cursor: pointer;
    position: relative;
    bottom: 0;
    top: 0 !important;
    flex-grow: 1;
    flex-basis: 0;
    border-top-right-radius: 0;
    transition-property: all;
    transition-duration: 500ms;
    transition-timing-function: var(--transition-timing);
    max-height: 100vh;
    z-index: 20;
  }
  section.draggable:hover,
  section.draggable.clicked {
    top: -95vh !important;
  }
  section.draggable .container {
    padding: 0.4rem 1rem !important;
    max-height: 100% !important;
  }

  i.invert {
    bottom: calc(5% + 1.5rem);
  }
}

@media (min-width: 481px) and (max-width: 1024px) and (orientation: landscape) {
  body {
    transform: translate(-50%, -50%) rotate(90deg);
    width: 100vh;
    height: 100vw;
    transform-origin: 50% 50%;
    position: absolute;
    left: 50%;
    top: 50%;
  }
}
